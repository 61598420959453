const state = {
    groups: [],
}

const getters = {
    groups: (state) => state.groups,
}

const actions = {
    // Getting all groups from the getRequest and committing a mutation to set received groups into our state.
    getGroups(context){
        context.dispatch("getRequest","getGroups").then(resp => {
            const data = JSON.parse(resp.body);
            console.log(data);
            context.commit("setGroups",data);
        })
    },

    // Deletes the group and commiting a mutation to delete the group from our state
    deleteGroup(context,payload){
        context.dispatch("postRequest",{
            "param":"deleteGroup",
            "payload":payload
        }).then(() => {
           context.commit("deleteGroup",payload);
        })
    }
}

const mutations = {

    emptyGroups(state){
        state.groups = [];
    },

    // Setting all received groups from the getRequest into our state.
    setGroups(state,payload){
        for(let group of payload){
            state.groups.push(group.group);
        }
    },

    // Updates the group or adds a new group into our state.
    updateGroup(state,payload){
        // If the group is new we unshift the group into our state.
        if(payload.isNewGroup){
            state.groups.unshift(payload.group);
            // Not a new group.
        }else{
            for(let group of state.groups){
                if(group.id === payload.group.id){
                    // updates the correct group from the state with the payload.
                    const index = state.groups.indexOf(group);
                    state.groups[index] = payload.group;
                }
            }
        }
    },

    // Deleting the group from our state.
    deleteGroup(state,payload){
        let counter = 0;
        for(let item of state.groups){
            if(item.id === payload.id){
                state.groups.splice(counter,1);
            }
            counter++;
        }
    }
}

const groupStore = {
    state,
    getters,
    actions,
    mutations
}

export default groupStore;